<template>
  <div style="background: #f6f6f6;color: #000;height: 100%;">
    <van-nav-bar title="给车主报价" left-arrow :fixed="true" :z-index="999" @click-left="back">
    </van-nav-bar>
    <div class="mian">
      <van-cell-group>
        <van-field label="车牌号：" placeholder="请输入" v-model="form.carNo" @click="show = true" readonly />
        <van-field label="入厂公里数：" v-model="form.driveMiles" placeholder="请输入" />
        <van-field label="车型：" v-model="form.carModel" placeholder="请输入" />
        <van-field label="车架号：" v-model="form.vin" placeholder="请输入" />
      </van-cell-group>
      <van-row class="xmdiv">
        <van-col span="10">项目/配件名称</van-col>
        <van-col span="6" class="cet">配件单价</van-col>
        <van-col span="5" class="cet">数量</van-col>
        <!-- <van-col span="5">工时费</van-col> -->
      </van-row>
      <div v-for="(item,idx) in form.orderItems" :key="idx">
        <van-row class="xmnr xmbj">
          <van-col span="16" class="xmname">{{idx+1}}、{{item.itemName}}</van-col>
          <!-- <van-col span="6">工时：66</van-col> -->
          <van-col class="pjs ris" span="4" @click="setpj(item)"><van-icon name="plus" />配件</van-col>
          <van-col class="red ris" span="4" @click="dltxm(idx)"><van-icon name="delete-o" size="18" /></van-col>
        </van-row>
        <van-row class="pjnr" v-for="(items,ids) in item.orderPartList" :key="ids">
          <van-col span="10" class="xmname">{{items.partName}}</van-col>
          <van-col span="6" class="cet">{{items.price}}</van-col>
          <van-col span="5" class="cet"><input type="number" v-model="item.counts"></van-col>
          <van-col span="3" class="cet red ris" @click="dltpj(item.orderPartList,ids)"><van-icon name="delete-o" size="18" /></van-col>
          <!-- <van-col span="5">{{items.inPrice}}</van-col> -->
        </van-row>
        <van-row class="xmnr">
          <van-col span="18">工时费：</van-col>
          <!-- <van-col span="6">工时：66</van-col> -->
          <van-col span="6" class="ris flxinp">
            ￥<input type="text" v-model="item.standPrice">
          </van-col>
        </van-row>
      </div>

      <div class="tjdiv" @click="setxiangmu">
        <van-icon size="20" style="margin-right: 4px;" name="add-o" />
        <span>添加项目</span>
      </div>
    </div>
    <van-popup class="pjdiv" v-model="xmshow" closeable>
      <div class="tjname" style="margin-top: 10px;">
        添加项目
      </div>
      <van-search class="pjss" v-model="searchxm" @input="goSearchxm" show-action placeholder="请输入搜索关键词">
        <template #action>
          <div @click="addxianmu">创建项目</div>
        </template>
      </van-search>
      <van-row class="titpj">
        <van-col span="14">项目名称</van-col>
        <van-col span="5" style="text-align: center;">价格</van-col>
        <van-col span="5" style="text-align: center;">类型</van-col>
      </van-row>
      <div class="pjfixdiv">
        <van-row class="pjmian" v-for="(item,idx) in xmlist" :key="idx" @click="setaddxianmu(item)">
          <van-col span="14">{{item.item}}</van-col>
          <van-col span="5" style="color: #d24848;text-align: center;">{{item.standPrice}}</van-col>
          <van-col span="5" style="text-align: center;">{{item.groupName}}</van-col>
        </van-row>
      </div>
    </van-popup>
    <van-popup class="pjdiv" v-model="pjshow">
      <div class="tjname">{{form.itemName}}</div>
      <div class="tjname" style="margin-top: 10px;">
        添加配件
        <van-checkbox @change="getpageListPC" v-model="checked" shape="square" icon-size="16px">库存</van-checkbox>
      </div>
      <van-search class="pjss" v-model="search" @input="goSearch" show-action placeholder="请输入搜索关键词">
        <template #action>
          <div @click="addpater">创建配件</div>
        </template>
      </van-search>
      <van-row class="titpj">
        <van-col span="14">配件名称</van-col>
        <van-col span="5" style="text-align: center;">价格</van-col>
        <van-col span="5" style="text-align: center;">库存</van-col>
      </van-row>
      <div class="pjfixdiv">
        <van-row class="pjmian" v-for="(item,idx) in kclist" :key="idx" @click="addpeijian(item)">
          <van-col span="14">{{item.partName}}</van-col>
          <van-col span="5" style="color: #d24848;text-align: center;">{{item.sellPrice}}</van-col>
          <van-col span="5" style="text-align: center;">{{item.total}}</van-col>
        </van-row>
      </div>
    </van-popup>
    <van-popup v-model="showqpx" position="right" :style="{ width: '85%' }">
      <van-search v-model="gysvalue" style="height: 60px;" show-action placeholder="请输入搜索关键词">
        <template #action>
          <van-button size="small" color="#0d906e" type="primary" @click.stop.prevent="getquerySupplierByGid">查找</van-button>
        </template>
      </van-search>
      <div class="list">
        <div class="list_a" v-for="(item,index) in QPlist" :key="index" @click="toggle(item)">
          <div style="padding: 2px 16px;overflow: hidden;" class="qipei_name">
            <span class="left">{{item.supplierName}}</span>
            <span class="right haoma">{{item.phone}}</span>
          </div>
          <div style="padding: 0px 16px 8px 16px;overflow: hidden;" class="qipei_w">
            <span class="left">{{item.scope}}</span>
            <span class="right wode" v-if="item.gid != null">我的</span>
          </div>
        </div>
        <div style="height: 45px;"></div>
      </div>
    </van-popup>
    <keyBoard v-model="value" v-show="show" @getjp="getjp" :show.sync="show"></keyBoard>
    <div style="height: 70px;"></div>
    <div class="bot">
      <!-- <div>合计：<span style="color: red;" class="kin">￥329.00</span></div> -->

      <van-button class="bost" type="danger" @click="setbaocun" size="small">保存询价</van-button>

    </div>
  </div>
</template>
  
<script>
import {
  pageListPC,
  partsave,
  itemGaragePage,
  itemsave,
  postquote,
  garageAskPriceaskPrice,
  querySupplierByGid,
  fixpay,
} from "@/api/check";
import userModel from "@/api/user";
import keyBoard from "@/components/vant-number-plate/vnp-keyboard";
import VnpInput from "@/components/vant-number-plate/vnp-input";
import preventReClick from "@/assets/preventReClick";
import { runInThisContext } from "vm";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  //   mixins: [preventBack], //注入

  components: {
    keyBoard,
    "vnp-input": VnpInput,
  },
  data() {
    return {
      // , "余额充值"
      list: ["代收款", "废品", "其他"], //
      carlist: [],
      gid: this.$route.query.gid,
      phoneisok: false,
      acvred: null,
      pjshow: false,
      searchxm: "",
      gysvalue: "",
      QPlist: [],
      xmshow: false,
      showqpx: false,
      phone: "",
      amount: "",
      amount2: "",
      remark: "",
      payType: 2,
      status: null, //工单计收还是预付款
      yfkisok: false,
      checked: false,
      kclist: [],
      search: "",
      acv: 0,
      form: {
        carModel: "",
        carNo: "",
        driveMiles: "",
        dis: 0,
        gid: 0,
        itemTotal: 0,
        partTotal: 0,
        remark: "",
        subtotal: 0,

        total: 0,
        id: 0,
        oid: 0,
        vin: "",
        orderItems: [],
      },
      statu: true,
      show: false,
      val: ["", "", "", "", "", "", "", ""],
      value: "",
      value2: "",
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")) || {},
      user: JSON.parse(localStorage.getItem("userInfo")),
      xmlist: [],
    };
  },
  watch: {
    value2(val) {
      if (val.length > 6) {
        this.getcarNo();
      }
    },
  },
  methods: {
    getjp(val) {
      var _this = this;
      _this.form.carNo = val;
    },
    freshData() {
      console.log("第一种调用方式");
    },
    getquerySupplierByGid() {
      var data = {
        isMy: true,
        standard: this.gysvalue,
        partTownId: "",
        page: 0,
        size: 30,
      };
      querySupplierByGid(data)
        .then((response) => {
          if (response.code == 200) {
            this.QPlist = response.data;
            this.QPlist.map((c, i) => {
              this.$set(c, "checked", false);
            });
          } else {
            this.$toast(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //询价
    toggle(item) {
      var _this = this;
      var dto = {
        askPricePart4GarageDTOList: [],
        handAskPictureInfo: [], //询价图片
        carModel: this.form.carModel, //车型
        carVin: this.form.vin, //车架号
        carVinPic: "", //车架照片
        carPicUrls: [], //车身照
        supplierList: [], //汽配商
        carBrand: "",
        flag: 1,
        counts: 6,
        oid: this.form.oid,
        gid: this.form.gid,
        partTotalCount: "", //配件数量
        billNumber: "", //工单号
        status: 0, //状态
      };
      var data = this.form.orderItems;
      dto.supplierList = [
        {
          companyName: item.supplierName,
          manageRemark: item.scope,
          id: item.id,
        },
      ];
      for (var i = 0; i < data.length; i++) {
        for (var j in data[i].orderPartList) {
          var pj_data = {
            partCount: data[i].orderPartList[j].counts,
            partId: data[i].orderPartList[j].partId,
            partName: data[i].orderPartList[j].partName,
            partPicUrls: data[i].orderPartList[j].partPicUrls,
            remark: "",
            isok: false,
            addisok: "false",
          };
          dto.askPricePart4GarageDTOList.push(pj_data);
        }
      }
      dto.partTotalCount = dto.askPricePart4GarageDTOList.length;

      garageAskPriceaskPrice(dto).then((res) => {
        console.log("res=>", res);
        _this.showqpx = false;
        if (res.code == 200) {
          let carNo = _this.form.carNo.split("");
          carNo.splice(2, 0, "·");
          carNo.join("");
          var carNos = carNo.join("");
          var pjname = "";
          for (var i = 0; i < dto.askPricePart4GarageDTOList.length; i++) {
            pjname += dto.askPricePart4GarageDTOList[i].partName + " ";
          }
          var map = {
            title:
              "(" +
              carNos +
              ")" +
              "【" +
              this.form.carModel +
              "】" +
              this.form.garageName,
            url:
              "https://shopping.car-posthouse.cn/enquiry?gid=" +
              _this.gid +
              "&id=" +
              res.data +
              "&oid=" +
              _this.form.oid,
            description: "【配件】" + pjname,
            image: "image_1",
          };
          let arr = JSON.stringify(map);
          if (_this.isiOS) {
            _this.setupWebViewJavascriptBridge((bridge) => {
              var navBack = bridge.callHandler(
                "ShareToWechat",
                arr,
                function responseCallback(responseData) {
                  // alert('走了这里' + responseData)
                  return responseData;
                }
              );
            });
          } else {
            window.androidjs.onCallbackIntent("ShareToWechat", arr, false);
          }

          setTimeout((res) => {
            this.$toast.success("询价成功！");
          }, 1000);
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    setbaocun() {
      // if (!/^1[23456789]\d{9}$/.test(this.phone)) {
      //   this.$toast("手机号码格式错误");
      //   return;
      // } else if (this.phone == "" || !this.phone) {
      //   this.$toast("请输入手机号码");
      //   return;
      // }
      if (this.form.carNo == "" || !this.form.carNo) {
        this.$toast("请输入车牌号");
        return;
      } else if (this.form.carModel == "" || !this.form.carModel) {
        this.$toast("请输入车型");
        return;
      }

      if (this.form.id == 0) {
        const loading = this.$toast.loading({
          mask: true,
          duration: 0,
          message: "加载中...",
        });
        this.form.gid = this.gid;
        postquote(this.form).then((res) => {
          loading.clear();
          if (res.code == 200) {
            this.form = res.data;
            this.getquerySupplierByGid();
          }
        });
      } else {
        this.getquerySupplierByGid();
      }
      this.showqpx = true;
    },
    goSearchxm() {
      // 保持this的指向始终指向vue实例
      const that = this;
      if (!that.statu) {
        return;
      }
      that.statu = false;
      setTimeout(function () {
        that.getitemGaragePage(); // 调用写好的方法
        that.statu = true;
      }, 200);
    },
    // 节流函数
    goSearch() {
      // 保持this的指向始终指向vue实例
      const that = this;
      if (!that.statu) {
        return;
      }
      that.statu = false;
      setTimeout(function () {
        that.getpageListPC(); // 调用写好的方法
        that.statu = true;
      }, 200);
    },
    //配件列表
    getpageListPC() {
      var data = {
        garageId: this.gid,
        size: 50,
        page: 0,
        search: this.search,
        source: this.checked ? "0" : "1",
      };
      pageListPC(data).then((res) => {
        console.log(res);
        this.kclist = res.data;
      });
    },
    //项目弹窗
    setxiangmu() {
      this.getitemGaragePage();
      this.xmshow = true;
    },
    //配件弹窗
    setpj(item) {
      this.xmform = item;
      this.getpageListPC();
      this.pjshow = true;
    },
    //添加项目到工单
    setaddxianmu(item) {
      var data = {
        counts: 1,
        gid: this.gid,
        groupId: item.groupId,
        groupName: item.groupName,
        itemId: item.id,
        itemName: item.item,
        standPrice: item.standPrice ? item.standPrice : 0,
        orderPartList: [],
      };
      this.form.orderItems.push(data);
      this.xmshow = false;
    },
    //删除项目
    dltxm(idx) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除该项目？",
        })
        .then(() => {
          this.form.orderItems.splice(idx, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    //删除配件
    dltpj(item, idx) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否删除该配件？",
        })
        .then(() => {
          item.splice(idx, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    //添加到项目配件
    addpeijian(v) {
      var data = {
        id: v.id,
        gid: v.gid,
        itemName: v.partName,
        itemId: this.xmform.itemId,
        partId: v.id,
        partName: v.partName,
        source: 1,
        unit: v.unit,
        price: v.sellPrice,
        inPrice: v.exceeds ? v.exceeds : 0,
        costCounts: 1,
        counts: 1,
      };
      this.xmform.orderPartList.push(data);
      this.pjshow = false;
    },
    //项目
    getitemGaragePage() {
      var data = {
        size: 50,
        page: 0,
        search: this.searchxm,
      };
      itemGaragePage(data).then((res) => {
        console.log(res);
        this.xmlist = res.data;
      });
    },
    //创建项目
    addxianmu() {
      var data = {
        counts: 1,
        groupId: 975,
        groupName: "备注",
        isFactioryName: 1,
        item: this.searchxm,
        oid: 0,
        standPrice: "0",
        gid: this.gid,
      };
      itemsave(data).then((res) => {
        if (res.code == 200) {
          this.getitemGaragePage();
        }
      });
    },
    //创建配件
    addpater() {
      var data = {
        gid: this.gid,
        isFactioryPartName: 1,
        partName: this.search,
        sellPrice: 0,
        total: 1,
      };
      partsave(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.checked = false;
          this.getpageListPC();
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    isWeixin() {
      //判断是否是微信
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.payType = 2;
        return true;
      } else {
        this.payType = 1;
        return false;
      }
    },
    //判断是否为支付宝坏境
    isAlipayClient() {
      if (navigator.userAgent.indexOf("AlipayClient") > -1) {
        this.payType = 1;
        return true;
      }
      this.payType = 2;
      return false;
    },
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    },
  },
  created() {
    // this.getpageListPC();
    // this.getitemGaragePage();
    // this.getquerySupplierByGid();
    //判断是否在微信环境
    this.appSource();
    this.isWeixin();
    return;
    if (this.$route.query.gid) {
      sessionStorage.setItem("gid", this.$route.query.gid);
    }
    userModel
      .getGarageInfo({ gid: this.$route.query.gid })
      .then((e) => {
        // loading.clear();

        this.shangjia = e.data;
        sessionStorage.setItem("appid", e.data.idd);
        sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        if (localStorage.getItem("phone")) {
          this.phone = localStorage.getItem("phone");
          this.setphone();
          this.phoneisok = false;
        } else {
          this.phoneisok = true;
        }
        // return;
        // if (this.isWeixin()) {
        //   var url =
        //     "https://shopping.car-posthouse.cn/ycjpay?gid=" +
        //     this.$route.query.gid;
        //   // 正式
        //   const redirect_uri = encodeURIComponent(url);
        //   window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        // }
      })
      .catch(() => loading.clear());
    // return
    // if (this.$route.query.code == undefined) {
    //   console.log(this.$route.query.code);
    //   var url = this.$route.path + "?gid=" + this.$route.query.gid;
    //   localStorage.setItem("beforeUrl", url);

    //   return;
    // } else {
    //   wxLogin({
    //     code: this.$route.query.code,
    //     gid: sessionStorage.getItem("gid"),
    //   }).then((e) => {
    //     if (e.code == 200) {
    //       localStorage.setItem("Authorization", e.data.authorization);
    //       localStorage.setItem("openId", e.data.openId);
    //       console.log(e);
    //     }
    //   });
    // }
  },
  mounted() {},
  computed: {},
};
</script>
  
<style lang="less" scoped>
.mian {
  padding-top: 46px;
  .xmdiv {
    line-height: 40px;
    background: #fff;
    font-size: 15px;
    // text-align: center;
    padding: 0 12px;
    margin-top: 10px;
    // font-weight: bold;
    position: sticky;
    top: 46px;
  }
  .cet {
    text-align: center;
  }
  .ris {
    text-align: right;
  }
  .pjnr {
    line-height: 40px;
    background: #fff;
    font-size: 14px;
    padding: 0 12px 0 22px;
    color: #666;
    border-bottom: 1px dashed #f6f6f6;
  }
  .red {
    color: #ee0a24;
  }
  .xmnr {
    line-height: 40px;
    background: #fff;
    font-size: 14px;
    // text-align: center;
    padding: 0 12px;

    .xmname {
      font-weight: bold;

      color: #333;
    }
    .pjs {
      color: #079a55;
    }
    .flxinp {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .xmbj {
    background-color: #f4f4f4;
  }
  input {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    height: 30px;
    width: 60px;
    padding: 0 10px;
  }
  .tjdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 40px;
    background: #fff;
    // line-height: 40px;
    padding: 10px 0;
    border-top: 1px solid #f4f4f4;
    font-size: 16px;
  }
}

.pjdiv {
  box-sizing: border-box;
  width: 90%;
  /* height: 500px; */
  font-size: 15px;
  padding: 15px 20px 20px 20px;
  .tjname {
    /* text-align: center; */
    line-height: 25px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  .pjss {
    padding-left: 0;
  }

  .inpss {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    line-height: 24px;
    margin: 5px 0;
    text-align: left;
    padding: 2px 12px;
    width: 80%;
  }

  .titpj {
    line-height: 40px;
    border-bottom: 1px solid #f1f1f1;
    font-weight: bold;
  }

  .pjfixdiv {
    height: 300px;
    overflow-y: auto;
  }

  .pjmian {
    /* line-height: 40px; */
    border-bottom: 1px solid #f1f1f1;
    color: #666;
    font-size: 14px;
    padding: 12px 0;
  }

  .imgsts {
    width: 20px;
    margin-left: 8px;
  }

  .pjmian:nth-child(2n) {
    background-color: #f9f9f9;
  }

  .van-popover--dark .van-popover__content {
    background-color: #1989fa;
  }

  .van-popover--dark .van-popover__arrow {
    color: #1989fa;
  }
}
.bot {
  position: fixed;
  height: 54px;
  background: #fff;
  z-index: 9;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  font-size: 14px;
  border-top: 1px solid #ebebeb;
  .bost {
    padding: 0 16px;
    font-size: 15px;
    width: 100%;
  }
  .kin {
    font-size: 15px;
  }
}
.list {
  height: calc(100vh - 60px);
  width: 100%;
  overflow-y: auto;
  .list_a {
    font-size: 13px;
    overflow: hidden;
    border-bottom: 1px solid #ebedf0;
  }
  .qipei_name {
    line-height: 24px;
    font-weight: bold;
  }
  .qipei_w {
    color: #646566;
  }
  .wode {
    background: #0d906e;
    color: #fff;
    width: 35px;
    height: 20px;
    border-radius: 5px;
    line-height: 20px;
    text-align: center;
  }
}
</style>

  
  